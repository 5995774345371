
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$evryword-primary: (
  50 : #fff2e1,
  100 : #ffdfb4,
  200 : #ffca82,
  300 : #ffb550,
  400 : #ffa52b,
  500 : #ff9505,
  600 : #ff8d04,
  700 : #ff8204,
  800 : #ff7803,
  900 : #ff6701,
  A100 : #ffffff,
  A200 : #fff7f2,
  A400 : #ffd5bf,
  A700 : #ffc4a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$evryword-warn: (
  50 : #f3e2e4,
  100 : #e1b8bc,
  200 : #cd888f,
  300 : #b95862,
  400 : #aa3540,
  500 : #9b111e,
  600 : #930f1a,
  700 : #890c16,
  800 : #7f0a12,
  900 : #6d050a,
  A100 : #ff9d9f,
  A200 : #ff6a6d,
  A400 : #ff373b,
  A700 : #ff1e22,
  contrast: (
    50 : #000000,
    100 : #ffffff,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$evryword-accent: (
  50 : #faefe1,
  100 : #f3d7b5,
  200 : #ebbd83,
  300 : #e3a351,
  400 : #dd8f2c,
  500 : #d77b07,
  600 : #d37306,
  700 : #cd6805,
  800 : #c75e04,
  900 : #be4b02,
  A100 : #ffeee6,
  A200 : #ffccb3,
  A400 : #ffaa80,
  A700 : #ff9867,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// $base-palette, $default: 500, $lighter: 100, $darker: 700, $text: $default
$evryword-primary: mat-palette($evryword-primary, 700, 100, 900, 900);
$evryword-accent: mat-palette($evryword-accent, 700, 100, 900, 900);

// The warn palette is optional (defaults to red).
$evryword-warn: mat-palette($evryword-warn, 700, 100, 900, 900);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$evryword-theme: mat-light-theme((
  color: (
    primary: $evryword-primary,
    accent: $evryword-accent,
    warn: $evryword-warn,
  )
));

$evryword-typography: mat-typography-config(
  $font-family: 'Varela Round, Roboto',
  $headline: mat-typography-level(32px, 48px, 400),
  $body-1: mat-typography-level(16px, 24px, 400),
  $title: mat-typography-level(24px, 32px, 400),
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-typography($evryword-typography);
@include angular-material-theme($evryword-theme);

$enable-responsive-font-sizes: true;
$evryword-theme-primary: map-get($evryword-theme, 'primary');
$text-primary-contrast: map.get($evryword-theme, 'primary', 'default-contrast');
$evryword-theme-accent: map-get($evryword-theme, 'accent');
$text-accent-contrast: map.get($evryword-theme, 'accent', 'default-contrast');
$evryword-theme-warn: map-get($evryword-theme, 'warn');
$text-warn-contrast: map.get($evryword-theme, 'warn', 'default-contrast');
$text-primary: map.get($evryword-theme, 'primary', 'default');
$text-accent: map.get($evryword-theme, 'accent', 'default');
$text-warn: map.get($evryword-theme, 'warn', 'default');
$scrollbar-width: 5px;

$primary: $text-primary;
$dark: map-get($map: $evryword-accent, $key: darker);

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

@mixin no-outline {
  outline: none !important;
  outline-style: none !important;
}

@mixin normalize-anchor {
  text-decoration: none !important;
  color: inherit !important;

  &:hover {
    color: auto !important;
    text-decoration: none !important;
  }
}

body {
  width: 100vw;
  overflow: hidden auto;

  a {
    @include normalize-anchor();
  }
    
  input, textarea {
    @include no-outline();
  }
  
  textarea {
    resize: none!important;
  }
}

::-webkit-scrollbar {
    width: $scrollbar-width;
    height: 1px;
}

::-webkit-scrollbar-track {
  background: map-get($map: $evryword-theme-primary, $key: lighter);
}

::-webkit-scrollbar-thumb {
  background: map-get($map: $evryword-theme-primary, $key: darker);
}
